import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'querystring';

import { checkToken } from 'redux/modules/auth';

const ValidationNgelesPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const { search } = location;
    const params = new URLSearchParams(search);
    const token = params.get('usr_token');
    const meetingId = params.get('meetingId');
    const slug = params.get('slug');
    const scheduleId = params.get('scheduleId');

    localStorage.setItem('token', token);

    dispatch(checkToken());
    history.push(
      `/livestream/ngeles/meeting?${qs.stringify({
        slug,
        meetingId,
        scheduleId,
      })}`,
    );
  }, []);

  return <div>Validation Ngeles</div>;
};

export default ValidationNgelesPage;
