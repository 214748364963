// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  apiKey: '',
  apiSecret: '',
  programTitle: '',
  programCategory: '',
  programScheduleId: '',
  programScheduleStartTime: '',
  programScheduleEndTime: '',
  programMeetingZoomId: '',
  programMeetingZoomPassword: '',

  programScheduleDetailId: '',
  programScheduleDetailDay: '',
  programSyllabusId: '',
  programSyllabus: null,
  programRecordedLivestream: '',
  role: 0,
};

const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLivestreamProgramData: (state, action) => ({
      ...state,
      apiKey: action.payload.apiKey,
      apiSecret: action.payload.apiSecret,
      programTitle: action.payload.programTitle,
      programScheduleId: action.payload.programScheduleId,
      programScheduleStartTime: action.payload.programScheduleStartTime,
      programScheduleEndTime: action.payload.programScheduleEndTime,
      programMeetingZoomId: action.payload.programMeetingZoomId,
      programMeetingZoomPassword: action.payload.programMeetingZoomPassword,

      programScheduleDetailId: action.payload.programScheduleDetailId,
      programScheduleDetailDay: action.payload.programScheduleDetailDay,
      programSyllabus: action.payload.programSyllabus,
      programRecordedLivestream: action.payload.programRecordedLivestream,
    }),

    setLivestreamProgramDetailData: (state, action) => ({
      ...state,
      programScheduleDetailId: action.payload.programScheduleDetailId,
      programScheduleDetailDay: action.payload.programScheduleDetailDay,
      programSyllabus: action.payload.programSyllabus,
      programRecordedLivestream: action.payload.programRecordedLivestream,
    }),
  },
});

export default reducer;

export const {
  setLivestreamProgramData,
  setLivestreamProgramDetailData,
} = actions;
