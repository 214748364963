import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkToken } from 'redux/modules/auth';
import { setLivestreamProgramData } from 'redux/modules/livestream';
import { useLocation, Redirect } from 'react-router-dom';
import { RotateSpinner } from 'react-spinners-kit';
import ZoomVideo from 'components/ZoomVideoMeeting';

const API_KEYS = {
  apiKey: process.env.REACT_APP_ZOOM_API_KEY,
  apiSecret: process.env.REACT_APP_ZOOM_API_SECRET_KEY,
};

const ZoomMeetingNgeles = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  // const [zoomConfig, setZoomConfig] = useState({
  //   apiKey: API_KEYS.apiKey, // can create from here https://marketplace.zoom.us/
  //   apiSecret: API_KEYS.apiSecret, // can create from here https://marketplace.zoom.us/
  //   meetingNumber: null,
  //   // meeting id generated when schedule a meeting in https://zoom.us/meeting/schedule
  //   // !!warning
  //   // meetingNumber must not have white space between numbers
  //   // 518 306 2219 - bad format
  //   // 5183062219 - good format
  //   leaveUrl: process.env.REACT_APP_WEBSITE_URL,
  //   userName: null, // (required)
  //   userEmail: null, // (optional) must be the attendee email address
  //   passWord: null,
  //   // !!warning, this is the passcode for meeting room, not for user password
  //   role: 0, // 0 for guest, 1 for host
  // });
  // const match = useRouteMatch();

  const checkAccessUserLivestream = async () => {
    try {
      const { search } = location;
      const params = new URLSearchParams(search);
      console.log(params);
      const slug = params.get('slug');
      const meetingIdParams = params.get('meetingId');
      const scheduleId = params.get('scheduleId');

      const token = localStorage.getItem('token');

      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          slug,
          scheduleId,
        },
      };

      const resultProgramAccess = await axios.get(
        `${process.env.REACT_APP_ENDPOINT_URL}/user-access/check-user-access`,
        options,
      );
      const { results: programAkademikListAccess } = resultProgramAccess.data;
      // console.log(resultProgramAccess);
      // console.log(programAkademikListAccess[0]);

      const {
        academic_program,
        schedule,
        non_academic_program,
      } = programAkademikListAccess[0];
      const { startTime, endTime, id: idSchedule, schedule_details } = schedule;

      const {
        id: idScheduleDetail,
        hari,
        recordedSproutVideo,
        programSyllabusId,
      } = schedule_details[0];

      if (academic_program) {
        const {
          category_academic_program,
          lecturer,
          judulProgram,
          meetingZoomId,
          meetingZoomPassword,
          program_syllabuses,
        } = academic_program;
        const {
          jenisKurikulum,
          jenjang,
          kelas,
          mataPelajaran,
        } = category_academic_program;

        if (meetingZoomId === meetingIdParams) {
          dispatch(
            setLivestreamProgramData({
              apiKey: API_KEYS.apiKey,
              apiSecret: API_KEYS.apiSecret,
              programTitle: judulProgram,
              programCategory: mataPelajaran,
              programScheduleId: idSchedule,
              programScheduleStartTime: startTime,
              programScheduleEndTime: endTime,
              programMeetingZoomId: meetingZoomId,
              programMeetingZoomPassword: meetingZoomPassword,

              programScheduleDetailId: idScheduleDetail,
              programScheduleDetailDay: hari,
              programSyllabus: program_syllabuses.find(
                (syllabusItem) => syllabusItem.id === programSyllabusId,
              ),
              programRecordedLivestream: recordedSproutVideo,
            }),
          );
          setRedirect(false);
          setLoading(false);
        } else {
          setRedirect(true);
        }
      } else {
        const {
          category_non_academic_program: categoryNonAkademik,
          sub_category_non_academic_program: subCategory,
          // lecturer,
          judulProgram,
          meetingZoomId,
          meetingZoomPassword,
          program_syllabuses,
        } = non_academic_program;

        if (meetingZoomId === meetingIdParams) {
          dispatch(
            setLivestreamProgramData({
              apiKey: API_KEYS.apiKey,
              apiSecret: API_KEYS.apiSecret,
              programTitle: judulProgram,
              programCategory: `${categoryNonAkademik.name} - ${subCategory.name}`,
              programScheduleId: idSchedule,
              programScheduleStartTime: startTime,
              programScheduleEndTime: endTime,
              programMeetingZoomId: meetingZoomId,
              programMeetingZoomPassword: meetingZoomPassword,

              programScheduleDetailId: idScheduleDetail,
              programScheduleDetailDay: hari,
              programSyllabus: program_syllabuses.find(
                (syllabusItem) => syllabusItem.id === programSyllabusId,
              ),
              programRecordedLivestream: recordedSproutVideo,
            }),
          );
          setRedirect(false);
          setLoading(false);
        } else {
          setRedirect(true);
        }
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setRedirect(true);
    }
  };

  useEffect(() => {
    dispatch(checkToken());
  }, []);

  useEffect(() => {
    setLoading(true);

    if (auth.tokenChecked) {
      if (auth.user.email) {
        checkAccessUserLivestream();
      } else {
        setLoading(false);
      }
    }
  }, [auth.tokenChecked]);

  if (loading) {
    return (
      <div className="row" style={{ height: '100%' }}>
        <div className="col" style={{ height: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <RotateSpinner size={50} color="#53b59f" loading />
          </div>
        </div>
      </div>
    );
  }

  if (redirect) {
    return <Redirect to="/livestream/validation" />;
  }

  return <ZoomVideo />;
};

export default ZoomMeetingNgeles;
