// below rule can be safely turned off thanks to immer used by redux toolkit
/* eslint-disable no-param-reassign */

import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

const initialUser = {
  id: null,
  email: null,
  profilePicture: null,
  role: null,
  nomorWhatsapp: null,
  name: null,
  gender: null,
  jenisKurikulum: null,
  jenjang: null,
  kelas: null,
  parentName: null,
  parentWhatsapp: null,
  parentEmail: null,
  parentWhatsappVerified: null,
  parentEmailVerified: null,
  tanggalLahir: null,
  provinsi: null,
  kota: null,
  isVerified: false,
  transaction: [],
};

export const initialState = {
  user: initialUser,
  token: null,
  tokenChecked: false,
  registerError: null,
  registerCheck: false,
  loggingIn: false,
  loginError: null,
};

const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.loggingIn = true;
      state.loginError = null;
    },
    loginSuccess: (state, action) => {
      state.loggingIn = false;
      state.tokenChecked = true;
      state.user = { ...state.user, ...action.payload };
    },
    loginFail: (state, action) => {
      state.loggingIn = false;
      state.loginError = action.payload || 'Login Failed';
      state.user = initialUser;
    },

    logout: (state) => {
      state.loggingOut = true;
    },
    logoutSuccess: (state) => {
      state.loggingOut = false;
      state.user = initialUser;
    },
    logoutFail: (state) => {
      state.loggingOut = false;
      state.logoutError = 'Insert payload error here';
    },
    register: (state) => {
      state.registerCheck = true;
      state.registerError = null;
    },
    registerFail: (state, action) => {
      state.registerCheck = false;
      state.registerError = action.payload || 'Register Failed';
      state.user = initialUser;
    },
    registerSuccess: (state, action) => {
      state.registerCheck = false;
      state.justRegistered = true;
      state.tokenChecked = true;
      state.user = { ...state.user, ...action.payload };
    },
    tokenValid: (state, action) => {
      state.tokenChecked = true;
      state.user = { ...state.user, ...action.payload };
    },
    tokenInvalid: (state) => {
      state.tokenChecked = true;
      state.user = initialUser;
    },
    updateUserData: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    setTransactionUser: (state, action) => {
      state.user.transaction = action.payload;
    },
  },
});

export default reducer;

export const {
  login,
  loginFail,
  loginSuccess,
  logout,
  logoutFail,
  logoutSuccess,
  register,
  registerFail,
  registerSuccess,
  tokenValid,
  tokenInvalid,
  updateUserData,
  setTransactionUser,
} = actions;

export const checkToken = () => {
  return async (dispatch) => {
    const token = localStorage.getItem('token');

    // dispatch(keepLoginStart());
    if (token) {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_URL}/auth/checkUserToken`,
          options,
        );

        const listTransactionUser = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_URL}/transaction/get-transaction`,
          options,
        );

        const { results } = listTransactionUser.data;

        dispatch(tokenValid(res.data.result));
        dispatch(setTransactionUser(results));
      } catch (err) {
        const message = err.response?.data.message || err.message;
        dispatch(tokenInvalid({ message }));
      }
    } else {
      dispatch(tokenInvalid({ message: 'No Token' }));
    }
  };
};

export const signOut = () => (dispatch) => {
  try {
    dispatch(logout());
    localStorage.removeItem('token');
    dispatch(logoutSuccess());
    dispatch(checkToken());
  } catch (err) {
    console.log(err);
    dispatch(logoutFail());
  }
};
