import React from 'react';
import { Route, Switch } from 'react-router-dom';

// pages
import ValidationNgelesPage from 'pages/validation/ngeles/ValidationNgelesPage';
import ZoomMeetingNgeles from 'pages/meeting/ZoomMeetingNgeles';
import NotFound from 'pages/notFound';

const App = () => {
  const Home = () => {
    return (
      <div>
        <h1>Livestream Ngeles Homepage</h1>
      </div>
    );
  };
  return (
    <div className="row">
      <div className="col-12 p-0">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route
            exact
            path="/livestream/ngeles"
            component={ValidationNgelesPage}
          />
          <Route
            exact
            path="/livestream/ngeles/meeting"
            component={ZoomMeetingNgeles}
          />
          <Route path="/not-found" component={NotFound} />
        </Switch>
      </div>
    </div>
  );
};

export default App;
