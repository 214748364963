import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ZoomMtg } from '@zoomus/websdk';
import { useLocation } from 'react-router-dom';

const joinMeeting = (signature, livestream, auth, leaveUrlObj) => {
  ZoomMtg.init({
    leaveUrl: `${process.env.REACT_APP_WEBSITE_URL}/kelas/livestream/${leaveUrlObj.slug}/${leaveUrlObj.scheduleId}`,
    isSupportAV: true,
    success(success) {
      console.log('Init Success ', success);
      ZoomMtg.join({
        meetingNumber: livestream.programMeetingZoomId,
        userName: auth.user.name,
        signature,
        apiKey: livestream.apiKey,
        passWord: livestream.programMeetingZoomPassword,

        success: (successResult) => {
          console.log(successResult);
        },

        error: (error) => {
          console.log(error);
        },
      });
    },
  });
};

const ZoomVideoMeeting = () => {
  const livestream = useSelector((state) => state.livestream);
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  const slug = params.get('slug');
  const scheduleId = params.get('scheduleId');
  const objectDataLivestreamLeaveUrl = {
    slug,
    scheduleId,
  };

  useEffect(() => {
    ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.0/lib', '/av');
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();

    if (livestream.programMeetingZoomId) {
      ZoomMtg.generateSignature({
        meetingNumber: livestream.programMeetingZoomId,
        apiKey: livestream.apiKey,
        apiSecret: livestream.apiSecret,
        role: livestream.role,
        success(res) {
          console.log('res', res);

          setTimeout(() => {
            joinMeeting(
              res.result,
              livestream,
              auth,
              objectDataLivestreamLeaveUrl,
            );
          }, 1000);
        },
      });
    }
  }, [livestream.programMeetingZoomId]);

  return <></>;
};

export default ZoomVideoMeeting;
